@import url("https://fonts.googleapis.com/css2?family=Inter&family=Montserrat&display=swap");

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
  background-color: #f5f5f5;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #333;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: #000000;
}

li {
  list-style: none;
}

.container {
  width: 1230px;
  margin: 0 auto;
  height: 100%;
}

.main-container {
  width: 1220px;
  margin: 0 auto;
  height: 100%;
}

.top-container {
  padding-top: 110px;
}

.input-wrapper {
  position: relative;
  margin-bottom: 20px;

  input {
    width: 100%;
    padding: 20px 15px 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 10px;
    outline: none;
  }

  .input-wrapper.error input {
    border-color: red;
  }

  .placeholder-label {
    position: absolute;
    top: 29px;
    left: 20px;
    transform: translateY(-50%);
    color: rgba(0, 0, 0, 0.4);
    font-size: 12px;
    transition: all 0.3s ease;
    pointer-events: none;

    &.active {
      top: 10px;
      left: 15px;
      font-size: 10px;
      color: rgba(0, 0, 0, 0.4);
    }
  }

  textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    font-size: 16px;
    font-family: sans-serif;
    border: 1px solid #ccc;
    border-radius: 10px;
    outline: none;
  }

  .error-message {
    color: red;
    opacity: 1;
    transition: opacity 0.3s ease;
  }
}

body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(195, 32, 51);
  // outline: 1px solid slategrey;
}

/* Responsive styles for screens up to 768px wide (e.g., tablets) */
@media (max-width: 768px) {
  .container {
    width: 100%;
    padding: 0 20px;
  }
}

/* Responsive styles for screens up to 480px wide (e.g., smartphones) */
@media (max-width: 480px) {
  .container {
    width: 100%;
    padding: 0 10px;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.pdf-container {
  padding: 20px;
  font-size: 10px;
  .pdf-logos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    img {
      width: 100px;
    }
  }
  h3 {
    text-align: center;
    padding: 10px;
  }
  .pdf-first-paragraph {
    border: 1px solid;
    padding: 20px 10px;
    margin: 10px;
    text-align: justify;
  }
  .pdf-forms {
    border: 1px solid;
    margin: 10px;
    p {
      border-bottom: 1px solid;
      padding: 20px;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
