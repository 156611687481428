.award-container {
  h1 {
    color: rgba(195, 32, 51, 1);
    margin-bottom: 40px;
  }
  h3 {
    color: rgba(195, 32, 51, 1);
    margin-bottom: 10px;
  }
}
.award-application-document-container {
  display: flex;
  flex-direction: row;
  padding: 20px 0px;
}
.award-application-document {
  width: 20%;
  img {
    margin: 10px 0px;
    cursor: pointer;
  }
  p {
    margin-bottom: 10px;
  }

  .award-application-document-calendar {
    display: flex;
    align-items: flex-end;
    gap: 5px;
  }

  .award-calendar-paragraph {
    font-size: 12px;
  }
}

.award-profile-right {
  width: 65%;
  background-color: white;
  padding: 20px 40px;
  border-radius: 10px;
}

.award-profile-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.award-profile-info {
  display: flex;
  gap: 100px;

  .award-profile-info-list {
    li {
      margin-top: 10px;
      color: rgba(0, 0, 0, 0.4);
      font-size: 12px;
    }
  }

  .award-profile-info-second-list {
    li {
      margin-top: 10px;
      font-size: 12px;
    }
  }
}

.award-edit {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
}


.edit-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.edit-modal-content {
  max-width: 400px;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
}

.edit-modal-content h2 {
  font-size: 18px;
  margin-bottom: 20px;
}

.edit-modal-content form {
  display: flex;
  flex-direction: column;
}

.edit-modal-content input {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.edit-modal-content button {
  padding: 8px 16px;
  margin-top: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.edit-modal-content button[type="submit"] {
  background-color: #28a745;
}

.edit-modal-content button[type="button"] {
  background-color: #dc3545;
}

