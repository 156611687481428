/* Base styles for all screen sizes */
.footer-container {
  background-color: #F5F5F7;
}

.footer-logos {
  display: flex;
  justify-content: center;
  padding: 20px;
  gap: 70px;
}

.footer-logos-sponsors {
  display: flex;
  align-items: center;
  gap: 20px;
}

.footer-second-container {
  background-color: #F5F5F7;
  color: #6E6E73;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  padding: 30px;
  border-top: 1px solid rgba(176, 176, 176, 0.288);

  p {
    display: flex;
    justify-content: center;
    font-size: 15px;
    font-weight: 300;
  }
}

.footer-menu{
  background-color: #F5F5F7;
  border-top: 1px solid rgba(176, 176, 176, 0.288);
}

/* Responsive styles for screens up to 768px wide (e.g., tablets and mobile devices) */
@media (max-width: 768px) {
  .footer-logos {
    display: flex;
    flex-direction: column;
    gap: 70px;

    .footer-logos-sponsors{
      display: flex;
      flex-direction: column;
    }
  }
  .footer-menu-container {
    flex-direction: column; 
    gap: 30px; 
    padding: 20px; 
    text-align: center; 

    .footer-menu-lists {
      border-right: none; 
      padding: 0; 

      ul {
        li {
          font-size: 18px;
          margin-bottom: 15px; 
        }

        li:hover {
          color: rgba(195, 32, 51, 1);
        }
      }
    }

    .footer-menu-subscribe,
    .footer-menu-contact {
      padding: 0; 
    }

    .footer-menu-subscribe-joinus {
      p {
        font-size: 20px;
      }

      input {
        padding: 8px; 
      }
    }

    .footer-menu-social-logo {

      img{
        width: 30px;
        margin: 20px;
        cursor: pointer;

      }
    }

    .footer-menu-social {
      flex-direction: column; 
      align-items: center; 
      gap: 10px;

      button {
        padding: 10px 20px; 
      }
    }
  }
}

/* Styles for screens larger than 768px wide (e.g., laptops and large widths) */
@media (min-width: 769px) {
  .footer-menu-container {
    display: flex;
    padding: 70px 0px;

    .footer-menu-lists {
      display: flex;
      gap: 90px;
      padding-right: 50px;
      width: 35%;
      
      ul {
        li {
          font-size: 16px;
          color: #6E6E73;
          font-weight: 300;
          margin-bottom: 20px;
          cursor: pointer;
          font-family: "Inter", Sans-serif;
        }

        li:hover {
          color: rgba(195, 32, 51, 1);
        }
      }
    }

    .footer-menu-subscribe {
      display: flex;
      gap: 10px;
      padding: 0 50px;
      align-items: self-start;
      justify-content: center;
      border-right: 1px solid rgba(176, 176, 176, 0.288);
      border-left: 1px solid rgba(176, 176, 176, 0.288);


      p {
        font-size: 20px;
        color:#6E6E73;
        font-weight: 700;
        font-family: "Inter", sans-serif;
        padding-bottom: 14px;
      }

      input {
        padding: 13px 17px;
        border-radius: 8px;
        outline: none;
        border: none;
        border-width: 0px;
        font-size: 16px;
      }

      .footer-menu-social-logo {
        display: flex;
        justify-content: space-between;
        padding-bottom: 13px;
        padding-left: 50px;

        img{
          width: 20px;
          cursor: pointer;
        }
      }

      button {
        padding: 13px 35px;
        border-radius: 8px;
        outline: none;
        border: none;
        background-color: rgba(215, 215, 215, 1);
        color: rgba(0, 0, 0, 1);
        font-weight: bold;
        font-size: 17px;
        cursor: pointer;
      }
    }



    .footer-menu-contact {
      padding-left: 50px;
      p {
        color: #6E6E73;
        font-weight: 300;
        font-size: 16px ;
        font-family: "Inter", sans-serif;
        width: 120%;
      }
      .underline-paragraph span {
        color: #c32033;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
