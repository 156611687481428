.grants-for-sme-container {
  padding-bottom: 20px;
}

.grants-application {
  display: flex;
  flex-direction: row;
  gap: 100px;
}

.grants-application-form {
  width: 100%;
}

.grants-application-form-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  p {
    color: rgba(195, 32, 51, 1);
  }
}

.grants-application-form-back {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  color: rgba(195, 32, 51, 1);
  cursor: pointer;
  padding-bottom: 30px;
}

.grants-application-instructions {
  width: 40%;
}

p.paragraph-date{
  font-size: 14px;
  font-weight: 900;
  color: #4e4e4e;
  margin: 5px 0px;
}

.grants-application-inputs {
  p{
    font-weight: bold;
    margin-bottom: 10px;
  }
}
