.button-container {
  display: flex;
  gap: 50px;
  flex-wrap: nowrap;
  justify-content: center;
  padding-top: 50px;
  button {
    padding: 20px;
    background-color: #c32033;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: ease-in 2s;
  }

  button:nth-child(2) {
    background-color: #20c32e;
  }

  &:hover{
    transition: all 0.1s ease-in;
  }
  
  h1{
    color: #c32033;
  }
}

.recharts-legend-wrapper{
  left: 35px;
}
