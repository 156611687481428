.forgot-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
  }
  
  .forgot-password-form-container {
    background-color: #fff;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }
  
  .forgot-password-title {
    h3 {
      font-size: 24px;
      margin-bottom: 20px;
      text-align: center;
      color: #333;
    }
  }
  
