.reset-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 500px;
    margin: 150px auto;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    
    .title {
      font-size: 24px;
      margin-bottom: 20px;
      color: #202124;
    }
  
    .code-input-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      width: 100%;
  
      input {
        width: 20%;
        padding: 10px;
        font-size: 18px;
        border: 1px solid #dadce0;
        border-radius: 4px;
        outline: none;
        text-align: center;
  
        &:focus {
          border-color: #4285f4;
        }
  
        &::placeholder {
          color: #80868b;
        }
      }
    }
  
    button {
      width: 100%;
      padding: 12px;
      font-size: 16px;
      border: none;
      border-radius: 4px;
      background-color: #1a73e8;
      color: #fff;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #005dbc;
      }
  
      &:disabled {
        background-color: #8ab4f8;
        cursor: not-allowed;
      }
    }
  }
  