.red-button {
    background-color: rgba(195, 32, 51, 1);
    padding: 15px;
    width: 100%;
    color: white;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    margin: 20px 0px;
    font-family: 'Montserrat';
    height: 63px;
  }
  
  .red-button:hover {
    background-color: darkred;
    transition: all 0.1s ease-in;
  }
  