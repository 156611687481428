.new-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
  
    .new-password-form-container {
      background-color: #fff;
      padding: 50px;
      width: 70%;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  
      .new-password-title {
        margin-bottom: 20px;
        text-align: center;
  
        h3 {
          margin: 0;
          font-size: 24px;
          color: #333;
        }
      }

    }
  }
  