.card-container {
  width: 100%;
  height: 230px;
  background: rgba(239, 239, 239, 1);
  padding: 10px;
  border-radius: 10px;
  position: relative;

  h3 {
    padding: 5px 0px;
  }

  p {
    padding-top: 10px;
  }
}

.card-second-paragraph {
  cursor: pointer;
  color: rgb(213, 17, 17);
  font-weight: 400;
  font-size: 14px;
  margin-top: 15px;
}

.card-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  align-content: center;
  color: black;
  padding: 20px 10px;
}

.card-button {
  display: flex;
  gap: 10px;
  padding-bottom: 10px;

  button {
    padding: 7px;
    font-size: 10px;
    color: white;
    background-color: rgba(195, 32, 51, 1);
    border: none;
    border-radius: 5px;
  }
}

.grant-apply-button {
  button {
    position: absolute;
    top: 40%;
    right: 50px;
    padding: 20px 60px;
    border: none;
    border-radius: 5px;
    background-color: rgb(195, 32, 51);
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: rgb(255, 255, 255);
    font-weight: 900;
    font-size: 13px;

    &:hover {
      background-color: rgb(196, 193, 193);
    }
  }
}

@media (max-width: 767px) {
  .card-container {
    height: auto;
  }

  .card-info {
    align-items: center;
  }

  .card-button {
    justify-content: center;
  }

  .grant-apply-button {
    button {
      top: 0;
      right: 0;
      left: 0;
      transform: translateY(-50%);
      padding: 7px 15px;
      font-size: 10px;
    }
  }
}
