.login-container-content {
  display: flex;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
}


.login-form-container {
  width: 100%;
  max-width: 678px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-title {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  justify-content: center;
  padding: 30px;
  font-size: 12px;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  padding: 40px;
  border-radius: 20px;
  width: 90%;
}

.login-form h3 {
  text-align: center;
  padding-bottom: 20px;
}

.login-form p {
  text-align: center;
}

.login-form span {
  text-align: right;
  color: rgba(203, 60, 77, 1);
  cursor: pointer;
}

.footer-login {
  color: rgba(195, 32, 51, 1);
  font-size: 12px;
  text-align: center;
  padding: 20px 0;
}

.password-input {
  position: relative;
}

.password-toggle {
  position: absolute;
  top: 27px;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.password-icon {
  color: #888;
}

.login-footer {
  color: rgba(195, 32, 51, 1);
  font-size: 12px;
  text-align: center;
  padding: 20px 0;
  width: 50%;
}

.login-footer-line {
  border: 0;
  border-top: 1px solid #ccc;
  margin: 10px 0;
}

.login-footer-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.login-footer-menu li {
  margin: 0 10px;
}   

.login-footer-menu a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  text-wrap :nowrap;
  
}

.login-footer-menu a:hover {
  color: #555;
}

.login-span-container{
  display: flex;
  justify-content: space-between;
}

/* Responsive styles */

/* For screens up to 768px wide (e.g., tablets) */
@media (max-width: 768px) {

  .login-container {
    width: 100%;
  }
  .login-form-container {
    padding: 10px; 
  }

  .login-form {
    padding: 20px;
  }

}

/* For screens up to 480px wide (e.g., smartphones) */
@media (max-width: 480px) {
  .login-form-container {
    padding: 5px; 
    width: 100%;
  }
  .login-container-content { 
    padding-top: 50px;
  }

  .login-form {
    padding: 10px; 
  }
  .image-container {
    width: 40%;
    display: none;
  }
}
