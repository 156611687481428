.progress-card-container {
  width: 100%;
  height: 177px;
  background: rgba(239, 239, 239, 1);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;

  h3 {
    padding: 5px 0;
  }zz

  p {
    padding-top: 10px;
  }
}

.progress-card-info {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  color: rgb(0, 0, 0);
  padding: 10px;
}

.progress-card-button {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 10px;

  .progress-card-button-first {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
  }

  .button-card-open,
  .button-card-applicants,
  .button-card-open-first {
    padding: 7px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .button-card-open {
    color: white;
    background-color: rgba(195, 32, 51, 1);
  }

  .button-card-open-first {
    color: rgba(195, 32, 51, 1);
    background-color: rgba(255, 255, 255, 1);
  }

  .button-card-applicants {
    background-color: rgba(255, 226, 230, 1);
    color: rgb(0, 0, 0);
  }
}

.progress-card-details-paragraph {
  margin: 5px 0;
  color: rgba(195, 32, 51, 1);
}

.progress-bar-container {
  width: 100%;
  height: 20px;
}

.progress-bar-background {
  width: 100%;
  height: 100%;
  background-color: rgb(221, 215, 215);
  border-radius: 10px;
}

.progress-bar {
  width: 50%;
  height: 100%;
  background-color: rgba(255, 150, 65, 0.678);
  border-radius: 10px;
  transition: width 0.7s ease;
}

@media (max-width: 767px) {
  .progress-card-container {
    height: auto;
  }

  .progress-card-info {
    flex-direction: row;
    gap: 10px;
  }

  .progress-card-button {
    flex-wrap: nowrap;
    align-items: center;
    gap: 10px;
  }

  .button-card-open,
  .button-card-applicants,
  .button-card-open-first {
    padding: 5px;
    font-size: 8px;
  }
}
