.table-container {
  margin-bottom: 20px;
  padding-bottom: 20px;

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 10px;
      text-align: left;
      border-bottom: 1px solid #ddd;
    }

    th {
      background-color: #f2f2f2;
    }

    tr:last-child td {
      border-bottom: none;
    }

    input {
      width: 320px;
      padding: 7px;
    }

    button {
      padding: 5px 10px;
      background-color: #ff0000;
      color: #fff;
      border: none;
      cursor: pointer;
      border-radius: 5px;

      &:hover {
        background-color: #ac7676;
      }

   
    }

    .total-row {
      font-weight: bold;
      float: left;
    }

    .total-label {
      text-align: right;
    }

    .total-price {
      font-size: 18px;
    }
  }
  button.addNewRow {
    padding: 5px 10px;
    background-color: #45a049;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
}
