/* Base styles for all screen sizes */
.navbar-container {
  background-color: #ffffff;
  padding: 35px;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  width: 100%;
}

.navbar-right-left {
  display: flex;
  justify-content: space-between;
}

.navbar-right {
  display: flex;
}

.navbar-logo img {
  width: 100%;
  height: auto;
}

.navbar-right-services {
  margin-left: 50px;
}

.navbar-right-services ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.navbar-right-services li {
  display: flex;
  align-items: center;
  margin-right: 20px;
  color: #333;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  padding: 5px;
}

.navbar-right-services li:hover,
.navbar-right-services li.active {
  background-color: rgba(239, 239, 239, 1);
  border-radius: 10px;
}

.navbar-right-services li:hover img,
.navbar-right-services li.active img {
  border-bottom: 3px solid rgba(195, 32, 51, 1);
}

.navbar-right-services li img {
  width: 20px;
  height: auto;
  margin-right: 5px;
  border-bottom: 1px solid transparent;
  transition: border-bottom 0.3s ease-in-out;
}

.navbar-left {
  display: flex;
  align-items: center;
  position: relative;
}

.navbar-search input {
  padding: 4px 6px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);

  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding-right: 25px;
  padding-left: 30px;
  outline: none;
  font-size: 14px;
}

.navbar-search input::placeholder {
  font-family: Inter;
  font-size: 14px;
}

.navbar-search svg {
  position: absolute;
  left: 10px;
  color: #666;
  top: 8px;
}

.navbar-profile {
  padding-left: 20px;

  button {
    padding: 4px 12px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background: #c32033;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: white;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    outline: none;
    border: none;
    margin-right: 30px;
  }
}

.select-language {
  select {
    border: none;
    outline: none;
    color: #c32033;
    font-size: 18px;
    font-weight: 700;
  }
}


/* Responsive styles for screens up to 768px wide (e.g., tablets and mobile devices) */
@media (max-width: 768px) {
  .navbar-container {
    padding: 10px;
  }

  .navbar-right-left {
    flex-direction: row;
    justify-content: center;
  }

  .navbar-right {
    margin-top: 10px;
    justify-content: space-between;
    width: 100%;
  }

  .navbar-right-services {
    margin: 0;
    display: none;
  }

  .navbar-right-services ul {
    flex-direction: row;
  }

  .navbar-right-services li {
    margin-right: 0;
    margin-bottom: 10px;
    justify-content: center;
  }

  .navbar-left {
    justify-content: center;
    align-items: center;
  }

  .navbar-search input {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .navbar-search svg {
    display: none;
  }

  .navbar-profile {
    padding-left: 0;

    button {
      padding: 4px 8px;
      font-size: 12px;
    }
  }

  /* Additional styles for the hamburger menu */
  .navbar-hamburger {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 22px;
    margin-left: 20px;
  }

  .hamburger-bar {
    width: 100%;
    height: 3px;
    background-color: #c32033;
    transition: transform 0.3s ease-in-out;
  }

  .navbar-hamburger.active .hamburger-bar:nth-child(1) {
    transform: translateY(7px) rotate(-45deg);
  }

  .navbar-hamburger.active .hamburger-bar:nth-child(2) {
    opacity: 0;
  }

  .navbar-hamburger.active .hamburger-bar:nth-child(3) {
    transform: translateY(-7px) rotate(45deg);
  }

  .navbar-hamburger-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    transform: translateY(-10px);
    visibility: hidden;
    opacity: 0;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }

  .navbar-hamburger.active ~ .navbar-hamburger-menu {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }

  .navbar-hamburger-menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .navbar-hamburger-menu li {
    padding: 10px;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .navbar-hamburger-menu li img {
    width: 20px;
    height: auto;
  }
}
