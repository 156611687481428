/* adminList.scss */

.applicant-list-container {
  border-radius: 20px;
  margin: 20px;

  .applicant-list {
    display: flex;
    align-items: center;
    padding: 15px;
    justify-content: space-between;
    border-bottom: 2px solid #b3aaaa4f;
  }

  h1 {
    margin: 20px 10px 30px 0px;
    color: rgba(195, 32, 51, 1);
  }

  p.applicant-header {
    color: rgba(195, 32, 51, 1);
    margin-bottom: 10px;
    font-size: 14px;
    width: 120px;
  }

  .disabled-button {
    background-color: gray;
  }

  .applicant-list-arrow {
    display: flex;
    gap: 10px;
    align-items: center;
    color: rgba(195, 32, 51, 1);
  }

  select { 
    padding: 5px;
    border: none;
    outline: none;
  }

  // Responsive adjustments
  @media (max-width: 768px) {
    .applicant-list {
      flex-direction: row;
      padding: 15px 0;
    }

    .applicant-button {
      justify-content: center;
      gap: 8px;
    }

    .applicant-list-arrow {
      margin: 10px 0;
    }
  }

  /* Additional styles for the table */
  .applicant-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    th,
    td {
      padding: 10px;
      border: 1px solid #b3aaaa4f;
      text-align: center;
    }
    th {
      background-color: rgba(195, 32, 51, 1);
      color: white;
    }
    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
  }

  .applicant-button {
    display: flex;
    gap: 10px;
    justify-content: center;

    button {
      border: none;
      border-radius: 10px;
      padding: 5px;
      color: rgb(255, 255, 255);
      cursor: pointer;
      text-transform: uppercase;
      transition: background-color 0.3s ease-in-out;

      &.btn-view {
        background-color: #b3b3b3;
      }

      &.btn-approve {
        background-color: #28a745;
      }

      &.btn-reject {
        background-color: #dc3545;
      }

      &.btn-visit {
        background-color: #28a745;
      }
    }
  }

  .export-list {
    margin: 20px 0px;
    button {
      border: none;
      border-radius: 10px;
      padding: 10px 20px;
      background-color: rgba(195, 32, 51, 1);
      color: white;
      cursor: pointer;
    }
  }
}
