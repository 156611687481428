// modal.scss

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  .modal-content {
    background-color: #fff;
    padding: 50px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .modal-buttons button {
    margin-left: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #ff0000;
    color: #fff;
    cursor: pointer;
  }

  .modal-buttons button:first-child {
    background-color: #28a745;
  }

  .modal-buttons button:hover {
    opacity: 0.8;
  }

  textarea {
    padding: 10px;
    width: 100%;
    border: 1px solid rgb(195, 192, 192);
    border-radius: 5px;
    outline: none;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
  }
}
