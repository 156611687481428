/* Base styles for all screen sizes */
.openCard-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Create spacing between cards */
  justify-content: center;
}

/* Adjust the card width for smaller screens */
.openCard-container .progressCard,
.openCard-container .NotApprovedCard,
.openCard-container .ApprovedCard,
.openCard-container .PhaseSecondCard,
.openCard-container .ApplicationReview {
  flex-basis: calc(50% - 20px); /* Two cards per row with spacing */
}

h2 {
  color: rgba(195, 32, 51, 1);
  padding: 30px 0px;
}

.eligibility-container {
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  align-content: center;
  justify-content: space-between;
  padding: 30px 0px;
}

.eligibility-title {
  width: 25%;
  font-size: 12px;
  font-weight: 900 !important;
}

.eligibility-title-right {
  width: 70%;

  p {
    text-align: start;
    font-size: 15px;
    line-height: 1.6em;
  }
}

.eligibility-paragraph {
  padding-top: 10px;
  p {
    width: 100%;
  }
}

.reporting-templates-right {
  width: 65%;
}

.reporting-application-document {
  width: 25%;

  p {
    font-size: 12px;
    margin: 10px 0;
  }

  .download-link {
    color: rgba(67, 57, 242, 1);
    text-decoration: underline;
    cursor: pointer;
  }
}

.progress-tracker-right {
  width: 65%;

  .progress-tracker-content {
    padding: 20px 15px;
    background: white;
    display: flex;
    flex-direction: column;
    width: 80%;
    border-radius: 10px;
  }

  .progress-tracker-header {
    padding-bottom: 20px;
  }

  .progress-tracker-data {
    display: flex;
    gap: 30px;

    &:not(:last-child) {
      border-right: 1px solid black;
      padding-right: 20px;
    }
    p {
      padding-bottom: 5px;
    }
    p.data-sum-bold {
      font-weight: bold;
    }
  }

  .progress-tracker-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 163px;
  }

  .progress-bar {
    position: relative;
    width: 100%;
    height: 28px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    overflow: hidden;
  }

  .progress-bar-inner {
    height: 100%;
    background-color: rgba(198, 199, 248, 1);
    width: 51%; /* fynamically */
    transition: width 0.3s ease-in-out;
  }

  .progress-percentage {
    position: absolute;
    top: 4px;
    left: 50%;
    transform: translateX(-50%);
    line-height: 20px;
    color: black;
    font-weight: bold;
  }
}

.resources-application-container {
  display: flex;
  gap: 20px;
}

p.resources-title {
  width: 187px;
  font-weight: bold;
}

.download-btn {
  border: none;
  padding: 8px 20px;
  border-radius: 8px;
  font-weight: bold;
  background-color: rgba(239, 239, 239, 1);
  cursor: pointer;
}

/* Responsive styles */

/* For screens up to 768px wide (e.g., tablets) */
@media (max-width: 768px) {
  /* Adjust the card width for smaller screens */
  .openCard-container .progressCard,
  .openCard-container .NotApprovedCard,
  .openCard-container .ApprovedCard,
  .openCard-container .PhaseSecondCard,
  .openCard-container .ApplicationReview {
    flex-basis: calc(50% - 10px); /* Two cards per row with reduced spacing */
  }

  /* Adjust font sizes for smaller screens */
  h2 {
    font-size: 24px;
  }

  .eligibility-container {
    flex-direction: column;
    padding: 20px 0;
  }

  .eligibility-title,
  .eligibility-title-right {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    font-size: 24px;
  }

  .eligibility-title p {
    font-size: 20px;
  }

  .eligibility-title-right p {
    padding: 10px;
    font-size: 20px;
  }

  .eligibility-paragraph p {
    width: 100%;
    text-align: center;
    font-size: 18px;
  }

  .reporting-application-document p,
  .progress-percentage {
    font-size: 10px;
  }
}

/* For screens up to 480px wide (e.g., smartphones) */
@media (max-width: 480px) {
  /* Adjust the card width for smaller screens */
  .openCard-container .progressCard,
  .openCard-container .NotApprovedCard,
  .openCard-container .ApprovedCard,
  .openCard-container .PhaseSecondCard,
  .openCard-container .ApplicationReview {
    flex-basis: 100%; /* Single card per row with full width */
  }

  /* Adjust font sizes for smaller screens */
  h2 {
    font-size: 20px;
  }

  .eligibility-container {
    flex-direction: column;
    padding: 20px 0;
  }

  .eligibility-title,
  .eligibility-title-right {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    font-size: 20px;
  }

  .eligibility-title p {
    font-size: 18px;
  }

  .eligibility-title-right p {
    padding: 10px;
    font-size: 16px;
  }

  .eligibility-paragraph p {
    width: 100%;
    text-align: center;
    font-size: 16px;
  }

  .reporting-application-document p,
  .progress-percentage {
    font-size: 10px;
  }
}

/* To hide horizontal overflow on the top container */
.top-container {
  overflow-x: hidden;
}
