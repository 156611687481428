/* Base styles for all screen sizes */
.signup-container{
  display: flex;
  justify-content: center;
}  

.image-container{
  width: 50%;
  img {
    height: 100vh;
    width: 100%;
    object-fit: cover;
  }
}
 

  


  


  

  

