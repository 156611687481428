// applicationPdf.scss

.container-pdf {
  padding: 20px;
  h1 {
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0px;
    color: #c32033;
  }

  p {
    font-size: 12px;
    margin-bottom: 5px;
    padding: 10px;
    border: 2px solid rgba(0, 0, 0, 0.13);
    border-radius: 10px;
  }
}

.signature-pdf {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  h4 {
    padding: 20px 0;
  }
}
