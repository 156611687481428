@import '~@fortawesome/fontawesome-svg-core/styles.css';

$accordionBackgroundColor: #f5f5f5;
$accordionTitleColor: #333;
$accordionContentColor: #666;

.accordion {
  background-color: $accordionBackgroundColor;
  border: 1px solid rgba(161, 174, 191, 1);
  border-radius: 10px;
  margin-bottom: 10px;

  button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    background-color: darken($accordionBackgroundColor, 1%);
    color: $accordionTitleColor;
    border: none;
    text-align: left;
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;

    &:hover {
      background-color: darken($accordionBackgroundColor, 5%);
      border-radius: 10px;
    }

    .arrow-icon {
        margin-left: auto;
      }
  }

  .content {
    padding: 10px;
    color: $accordionContentColor;
  }
}
