.breadcrumbs {
  margin-bottom: 20px;
}

.breadcrumb-list {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
  display: flex;
  gap: 4px;

  li {
    display: inline-block;

    &:not(:last-child)::after {
      content: "/";
      margin: 0 5px;
      color: #888;
    }

    &.active {
      color: rgba(28, 28, 28, 1);
      font-weight: bold;
    }

    a {
      color: rgba(0, 0, 0, 0.4);
      text-decoration: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
